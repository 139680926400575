import { Button } from '@progress/kendo-react-buttons';

interface AddButtonProps {
  onClick(): void
}

const AddButton = ({ onClick }: AddButtonProps): React.ReactElement => {
  return (
    <div className="filter-button">
      <Button themeColor='primary' onClick={onClick} icon='plus' title='Add'>
        Add
      </Button>
    </div>
  );
};

export default AddButton;
