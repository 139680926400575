import request from './request';
import endpoints from './endpoints';
import { SharedLink, StorageItem, StorageItemFull } from '../constants/document';

const getDocumentsList = async (): Promise<StorageItem[]> =>
  request({
    url: endpoints.documents.full.replace("{appId}", process.env.REACT_APP_ID ?? ''),
    method: 'GET'    
  });

const getDocumentInfo = async (fullPath: string): Promise<StorageItemFull> =>
  request({
    url: endpoints.documents.getDocument.replace("{appId}", process.env.REACT_APP_ID ?? ''),
    method: 'POST',
    data:{
      fullPath
    }    
  });


const createSharedLink = async (fullPath: string, startDate:Date, endDate:Date): Promise<SharedLink> =>
  request({
    url: endpoints.documents.createSharedLink.replace("{appId}", process.env.REACT_APP_ID ?? ''),
    method: 'POST',
    data:{
      fullPath,
      startDate: `${startDate.getMonth() + 1}/${startDate.getDate()}/${startDate.getFullYear()}`,
      expirationDate: `${endDate.getMonth() + 1}/${endDate.getDate()}/${endDate.getFullYear()}`,
    }    
  });

const openSharedFileLink = async (code: string): Promise<StorageItemFull> =>
  request({
    url: endpoints.documents.openSharedFileLink.replace("{appId}", process.env.REACT_APP_ID ?? '').replace("{code}", code),
    method: 'GET'
  });

const openSharedFolderLink = async (code: string): Promise<StorageItemFull[]> =>
  request({
    url: endpoints.documents.openSharedFolderLink.replace("{appId}", process.env.REACT_APP_ID ?? '').replace("{code}", code),
    method: 'GET'
  });

const deleteDocument = async (fileName: string, folderName:string): Promise<boolean> =>
  request({
    url: endpoints.documents.delete.replace("{appId}", process.env.REACT_APP_ID ?? ''),
    method: 'POST',
    data: {
      folder: folderName,
      fileName
    }    
  });

const renameDocument = async (oldFileName: string, newFileName: string, folderName:string): Promise<boolean> =>
  request({
    url: endpoints.documents.rename.replace("{appId}", process.env.REACT_APP_ID ?? ''),
    method: 'POST',
    data: {
      folder: folderName,
      oldFileName,
      newFileName
    }    
  });

const renameFolder = async (oldFolderName: string, newFolderName: string, path:string): Promise<boolean> =>
  request({
    url: endpoints.documents.folderRename.replace("{appId}", process.env.REACT_APP_ID ?? ''),
    method: 'POST',
    data: {
      oldFolderName,
      newFolderName,
      path
    }    
  });

const deleteFolder = async (folderName:string): Promise<boolean> =>
  request({
    url: endpoints.documents.folderDelete.replace("{appId}", process.env.REACT_APP_ID ?? ''),
    method: 'POST',
    data: {
      folder: folderName
    }    
  });

const deleteContentFolder = async (folderName:string): Promise<boolean> =>
  request({
    url: endpoints.documents.folderContentDelete.replace("{appId}", process.env.REACT_APP_ID ?? ''),
    method: 'POST',
    data: {
      folder: folderName
    }    
  });

const addFolder = async (newFolderName: string, folderName:string): Promise<boolean> =>
  request({
    url: endpoints.documents.folderAdd.replace("{appId}", process.env.REACT_APP_ID ?? ''),
    method: 'POST',
    data: {
      folder: folderName,
      newFolderName
    }    
  });

const documentsService = {
  getDocumentsList,
  renameDocument,
  renameFolder,
  deleteDocument,
  addFolder,
  deleteFolder,
  deleteContentFolder,
  getDocumentInfo,
  createSharedLink,
  openSharedFileLink,
  openSharedFolderLink
};

export default documentsService;
