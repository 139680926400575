import { useSelector } from 'react-redux';
import { Menu, MenuItem } from '@progress/kendo-react-layout';
import './profile-menu.css';
import { getRole, selectUserName } from '../../reducers/user/selectors';
import routes from '../../constants/routes.json';
import { useNavigate } from 'react-router-dom';
import { Roles } from '../../constants/app';

const ProfileMenu = () => {
  const user = useSelector(selectUserName);
  const navigate = useNavigate();
  const role = useSelector(getRole);

  const onSelect = (event: any): void => {
    const { route } = event.item.data || {};
    if (route) {
      navigate(route);
    }
   
  };
  
  return user === '' || role === ''
    ? <></>
    : (
      <Menu onSelect={onSelect} openOnClick className="profile-wrapper">
        <MenuItem text={user} icon="user user-icon">
          {role === Roles.Admin ? <MenuItem text="Documents" icon="file" data={{ route: routes.documents }} /> :<></>}
          {role === Roles.Admin ? <MenuItem text="Access" icon="unlock" data={{ route: routes.roles }} /> :<></>}
          {role === Roles.Admin ? <MenuItem text="Users" icon="tell-a-friend" data={{ route: routes.users }} /> :<></>}
          <MenuItem text="Log out" icon="logout" data={{ route: routes.signin }} />        
        </MenuItem>
      </Menu>
  );
};

export default ProfileMenu;
