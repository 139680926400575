import { Form, FormElement, Field, FormRenderProps } from '@progress/kendo-react-form';
import { UserForm } from './@types';
import styles from './UserDetail.module.css';
import { requiredValidator, emailOrAccountValidator, passwordValidator, isDornaUser } from '../common/forms/validators';
import { FormInput } from '../common/forms/form-components';
import RoleSelector from '../common/base/RoleSelector';
import { Button } from '@progress/kendo-react-buttons';

interface IUserDetailProps {
  user?: UserForm;
  onSubmit(user: UserForm): void;
}

const UserAdd = ({ user, onSubmit }: IUserDetailProps): React.ReactElement => {
  const onFormSubmit = (
    values: {
      [name: string]: any;
    },
    event?: React.SyntheticEvent<any, Event> | undefined,
  ): void => {
    if (event) {
      event.preventDefault();
    }

    onSubmit({ ...(values as UserForm) });
  };

  const requiredFieldsValidator = (formRenderProps: any): boolean => {
    var name = formRenderProps.valueGetter('name');
    var surname = formRenderProps.valueGetter('surname');
    var email = formRenderProps.valueGetter('email');
    var role = formRenderProps.valueGetter('role');

    var password = formRenderProps.valueGetter('password');
    var passwordConfirmation = formRenderProps.valueGetter('passwordConfirmation');

    if (isDornaUser(email))
      return name && surname && email && role && role.code;
    else 
      return password && passwordConfirmation && password === passwordConfirmation  &&
            name && surname && email && role && role.code;
  };

  return (
    <div className={styles['user-detail']}>
      <h3
        className={['k-form-legend', styles['custom-legend']].join(' ')}
      >{`New contact:`}</h3>
      <Form
        key={JSON.stringify(user)}
        initialValues={user}
        onSubmit={onFormSubmit}
        render={(formRenderProps: FormRenderProps): any => (
          <FormElement>
            <fieldset className="k-form-fieldset">
              <div className={styles['fields-wrapper']}>
                <div className={styles['field-panel']}>
                <Field
                    key="email"
                    name="email"
                    type="email"
                    component={FormInput}
                    label="Email / Username"
                    validator={emailOrAccountValidator}
                    id="email"
                  />
                  <Field
                    key="name"
                    name="name"
                    component={FormInput}
                    label="Name"
                    validator={requiredValidator}
                    id="name"
                  />
                  <Field
                    key="surname"
                    name="surname"
                    component={FormInput}
                    label="Surname"
                    validator={requiredValidator}
                    id="surname"
                  />                  
                  <Field
                    key="role"
                    name="role"
                    component={RoleSelector}
                    label="Role"
                    validator={requiredValidator}
                    id="role"
                  />
                </div>
                <div className={styles['field-panel']}>
                <Field
                    key="password"
                    name="password"
                    component={FormInput}
                    type="password"
                    label="Password"
                    validator={(value: string) => !isDornaUser(formRenderProps.valueGetter('email')) ? passwordValidator(value) : ''}
                    id="password"
                    disabled={isDornaUser(formRenderProps.valueGetter('email'))}
                  />
                  <Field
                    key="passwordConfirmation"
                    name="passwordConfirmation"
                    component={FormInput}
                    type="password"
                    label="Confirm password"
                    validator={(value: string) => !isDornaUser(formRenderProps.valueGetter('email')) ? passwordValidator(value) : ''}
                    id="passwordConfirmation"
                    disabled={isDornaUser(formRenderProps.valueGetter('email'))}
                  />
                </div>
              </div>
            </fieldset>
            <div role="toolbar" className="k-form-buttons">
              <Button
                themeColor="primary"
                disabled={!requiredFieldsValidator(formRenderProps)}
                onClick={formRenderProps.onSubmit}
              >
                Submit
              </Button>
            </div>
          </FormElement>
        )}
      />
    </div>
  );
};

export default UserAdd;
