import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

interface ConfirmationProps {
  title?: string;
  message: string;
  onDecline?(): void;
  onAccept?(): void;
  children?: any;
}

const Confirmation = ({
  children, title = 'Please confirm', message, onDecline, onAccept,
}: ConfirmationProps): React.ReactElement => (
  <Dialog title={title} onClose={onDecline}>
    <p>{message}</p>
    <DialogActionsBar>
      {children || (
        <>
          <button type="button" className="k-button-md" onClick={onDecline}>No</button>
          <button type="button" className="k-button-md" onClick={onAccept}>Yes</button>
        </>
      )}
    </DialogActionsBar>
  </Dialog>
);

export default Confirmation;
