import { useState } from 'react';
import { Avatar } from '@progress/kendo-react-layout';
import { ListBox } from '@progress/kendo-react-listbox';
import { process, SortDescriptor } from '@progress/kendo-data-query';
import styles from './RoleList.module.css';
import { useEffect } from 'react';
import { RoleResume } from '../../services/@types/account';
import { RoleListProps } from './@types';
import _ from 'lodash';
import RolesFilters from './RolesFilters';

interface DataState {
  sort: SortDescriptor[];
  filter: any;
}

const initialFilter: DataState = {
  sort: [{ field: 'name', dir: 'asc' }],
  filter: {
    logic: 'or',
    filters: [],
  },
};

const RolesList = ({ roles, onSelect, onAddRole }: RoleListProps): React.ReactElement => {
  const [filterState, setFilterState] = useState(initialFilter);
  const [rolesFiltered, setRolesFiltered] = useState(roles);
  
  useEffect(() => {
    if (filterState.filter.filters.length > 0) {
      setRolesFiltered(process(roles, filterState).data);
    } else if (roles.length > 0) {
      setRolesFiltered([...roles]);
    }
  }, [roles, filterState]);

  const getInitials = (name: string): string => name ? `${name.trim().charAt(0)}` : '';

  const onFilterChange = (filters: any[]): void => {
    if (filters.length > 0) {
      const newFilter = {
        logic: 'and',
        filters: filters,
      };
      setFilterState({ ...filterState, filter: newFilter });
    } else if (!_.isEqual(filterState.filter.filters, filters)) {
      setFilterState(initialFilter);
    }
  };

  const updateRoleList = (id: string): RoleResume[] => {
    const roles: RoleResume[] = rolesFiltered.map((item: RoleResume) => {
      return { ...item, selected: item.id === id };
    });

    return roles;
  };

  const onClick = (event: any) => {
    if (event) {
      event.preventDefault();
    }

    const roleId = event.currentTarget.dataset.id;

    setRolesFiltered(updateRoleList(roleId));
    onSelect(roleId);
  };

  const ListItem = ({ dataItem }: any): React.ReactElement => {
    const { id, name, selected } = dataItem as RoleResume;

    return (
      <div
        role="none"
        key={id}
        className={selected ? `${styles['selected']} ${styles['list-item']}` : `${styles['list-item']}`}
        onClick={onClick}
        data-id={id}
      >
        <div className={styles['role-avatar']}>
          <Avatar rounded="full" type="text">
            {getInitials(name)}
          </Avatar>
        </div>
        <div className="text-ellipsis">
          <p className={`${styles['list-item-title']} text-ellipsis`}>{`${name}`}</p>          
        </div>
      </div>
    );
  };

  return (
    <div className={`${styles['roles-list']} ${styles['filter-element']}`}>
      <RolesFilters roles={rolesFiltered} onFilter={onFilterChange} onAddClick={onAddRole} />
      <ListBox
        data={rolesFiltered}
        item={ListItem}
        style={{ flex: 1, display: 'flex' }}        
        className={styles['listbox-content']}
        textField="name"
        selectedField="selected"
      />
      <div className={styles['users-footer']}>{`${rolesFiltered.length} roles`}</div>
    </div>
  );
};

export default RolesList;
