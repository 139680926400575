import { useEffect } from 'react';
import { NotificationEvent, Notification } from '@progress/kendo-react-notification';

interface ToastProps {
  notification: any;
  onClose(id: string): void;
}

const Toast = ({ onClose, notification: { id, message, type } }: ToastProps): React.ReactElement => {
  useEffect(() => {
    const duration = 5000;
    const timmerId = setTimeout(() => onClose(id), duration);

    return (): void => clearTimeout(timmerId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Notification
      type={{ style: type, icon: true }}
      closable
      onClose={(e: NotificationEvent): void => {
        e.nativeEvent.preventDefault();
        onClose(id);
      }}
      key={id}
    >
      <span>{message}</span>
    </Notification>
  );
};

export default Toast;
