import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { showNotification } from '../actions/notifications';
import NotificationsTypes from '../constants/notification';
import { getToken } from '../reducers/account/selectors';
import store from '../store/store';

const client = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL_DOCUMENTS,
});

client.interceptors.request.use(
  (config) => {
    const token = getToken(store.getState());
    if (token && config && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

client.interceptors.response.use(
  (response) => response, 
  (error)=>{
    if (error.response.status !== 401) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
    const { dispatch } = store;

    setTimeout(() => {
      dispatch<any>(showNotification({ message: 'Your session has expired.', type: NotificationsTypes.Error }));
      window.location.href = `/?${new Date().getTime()}`;
    }, 2000);
  }
);

const request = async (options: AxiosRequestConfig): Promise<any> => {
  const onSuccess = (response: AxiosResponse): Promise<any> => {
    if (typeof response.data === 'object' && response.data instanceof Blob) {
      const filename = response.headers['content-disposition']?.split('"')[1] || 'document';
      const blobResponse = new File([response.data as BlobPart], filename, { type: response.data.type });
      return Promise.resolve(blobResponse);
    }

    return Promise.resolve(response.data);
  };

  const onError = (error: AxiosError): Promise<never> => {
    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.error('Status:', error.response.status);
      console.error('Data:', error.response.data);
      console.error('Headers:', error.response.headers);
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error('Error Message:', error.message);
    }

    return Promise.reject(error.response || error.message);
  };

  try {
    const response = await client(options);
    if ((response as unknown as AxiosError).isAxiosError) {
      return onError(response as unknown as AxiosError);
    }

    return onSuccess(response);
  } catch (error: any) {
    return onError(error);
  }
};

export default request;
