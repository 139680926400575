import { createSelector } from '@reduxjs/toolkit';
import { User } from '../@types/user';
import { RootState } from '../rootReducer';

const userSelector = (state: RootState): User => state.user;

const selectUserName = createSelector(
  [userSelector],
  (user: User) => `${user.name} ${user.surname}`,
);

const getUserId = createSelector(
  [userSelector],
  (user: User) => user.id,
);

const getRole = createSelector([userSelector], (user: User) => user.role);

const getAuthorizations = createSelector([userSelector], (user: User) => user.authorizations);

export {
  selectUserName,
  getRole,
  getUserId,
  getAuthorizations
};
