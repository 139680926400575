import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showNotification } from '../../actions/notifications';
import { UserResume, UserRequest, Role } from '../../services/@types/account';
import UsersList from './UsersList';
import styles from './UsersManagement.module.css';
import { hideLoading, showLoading } from '../../actions/api';
import UsersService from '../../services/usersService';
import NotificationsTypes from '../../constants/notification';
import UserDetail from './UserDetail';
import { UserForm } from './@types';
import UserChangePassword from './UserChangePassword';
import { Card, CardBody } from '@progress/kendo-react-layout';
import UserAdd from './UserAdd';

const UsersManagement = (): React.ReactElement => {
  const [usersState, setUsersState] = useState<UserResume[]>([]);
  const [selectedUserState, setSelectedUserState] = useState<any>();
  const [roles, setRoles] = useState<Role[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    let didCancel = false;

    if (!didCancel) {
      getRoles().then((rolesArray)=>getUsers(rolesArray));            
    }

    return (): void => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRoles = async (): Promise<Role[] | undefined> => {
    try {
      dispatch(showLoading());
      const data = await UsersService.getAllRoles();
      setRoles(data);
      return data;
    } catch (error: any) {
      dispatch(showNotification({ message: 'Unable to retrieve the list of roles.', type: NotificationsTypes.Error }));
    } finally {
      dispatch(hideLoading());
    }
  };

  const getUsers = async (rolesArray: Role[] | undefined = undefined) => {
    try {
      dispatch(showLoading());
      const users = await UsersService.getAllUsers();
      const [firstElement] = users;
      firstElement.selected = true;
      setUsersState(users);
      setSelectedUserState({ ...firstElement, role: (rolesArray ?? roles)?.find((r) => r.code === firstElement?.role) });
    } catch (error: any) {
      dispatch(showNotification({ message: 'Unable to retrieve the list of users.', type: NotificationsTypes.Error }));
    } finally {
      dispatch(hideLoading());
    }
  };

  const updateUserList = (user: UserResume): UserResume[] => {
    const users: UserResume[] = usersState.map((item: UserResume) => {
      if (item.id === user.id) {
        return user;
      }
      return item;
    });

    return users;
  };

  const onSubmit = async (user: UserForm): Promise<void> => {
    const { createUser, updateUser } = UsersService;
    try {
      dispatch(showLoading());
      if (user.id === '') {
        await createUser({ ...user, role: user.role.code } as any);
        getUsers();
      } else {
        await updateUser({
          ...user,
          role: user.role.code,
          userId: user.id,
        } as any);
        setUsersState(
          updateUserList({
            ...user,
            role: user.role.code,
          } as any),
        );
        onSelected({
          ...user,
          role: user.role.code,
        } as any);
      }
      dispatch(showNotification({ message: 'User updated.', type: NotificationsTypes.Success }));
    } catch (error: any) {
      const { status } = error;
      if (status && status === 400) {
        dispatch(
          showNotification({
            message: 'Email already registered, edit user to update the data.',
            type: NotificationsTypes.Error,
          }),
        );
      } else {
        dispatch(showNotification({ message: 'Unable to update the user.', type: NotificationsTypes.Error }));
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  const onSubmitPasswordChange = async (changePassword: any): Promise<void> => {
    const { updatePassword } = UsersService;
    try {
      dispatch(showLoading());
      await updatePassword(changePassword);
      onSelected(usersState.filter((u) => u.email === changePassword.email)[0]);
      dispatch(showNotification({ message: 'User password updated.', type: NotificationsTypes.Success }));
    } catch (error: any) {
      dispatch(showNotification({ message: 'Unable to update the password.', type: NotificationsTypes.Error }));
    } finally {
      dispatch(hideLoading());
    }
  };

  const setUserStatus = async (user: UserForm): Promise<void> => {
    const { activate, deactivate } = UsersService;
    try {
      dispatch(showLoading());
      if (user.isActive) {
        await activate({ id: user.id } as UserRequest);
      } else {
        await deactivate({ id: user.id } as UserRequest);
      }
      setSelectedUserState(user);
      dispatch(showNotification({ message: 'User status updated.', type: NotificationsTypes.Success }));
    } catch {
      dispatch(showNotification({ message: 'Unable to update user status.', type: NotificationsTypes.Error }));
    } finally {
      dispatch(hideLoading());
    }
  };

  const onSelected = async (user: UserResume | undefined): Promise<void> => {
    setUsersState(usersState.map((u) => ({ ...u, selected: u.id === user?.id })));

    setSelectedUserState({ ...user, 
                          role: roles?.find((r) => r.code === user?.role), 
                          selected: true });
  };

  const addUser = (): void => {
    var newUser = {
      id: '',
      name: '',
      surname: '',
      email: '',
      role: '',
      password: '',
      passwordConfirmation:'',
      selected: true,
      isActive: false,
    };

    setUsersState([...usersState, newUser]);
    onSelected(newUser);
  };

  return (
    <div className={styles['users-wrapper']}>
      <UsersList users={usersState} onSelect={(id: string)=> onSelected(usersState.find(u=> u?.id === id))} onAddUser={addUser} />
      <div className={styles['user-card-container']}>
        {selectedUserState?.id === '' ? (
          <UserAdd user={selectedUserState} onSubmit={onSubmit}/>
        ): (    
          <>
            <Card className={styles['user-card-item']}>
              <CardBody>
                <UserDetail user={selectedUserState} onSubmit={onSubmit} setUserStatus={setUserStatus} />
              </CardBody>
            </Card>
            {selectedUserState?.email === undefined ||
            (!selectedUserState.email.includes('@dorna.com') && !selectedUserState.email.includes('@motogp.com')) ? (
              <Card className={styles['user-card-item']}>
                <CardBody>
                  <UserChangePassword user={selectedUserState} onSubmit={onSubmitPasswordChange} />
                </CardBody>
              </Card>
            ) : null}
          </>   
      )}
      </div>
    </div>
  );
};

export default UsersManagement;
