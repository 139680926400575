import { LayoutRouteProps } from 'react-router-dom';
import { AppBar, AppBarSection, AppBarSpacer } from '@progress/kendo-react-layout';
import { Typography } from '@progress/kendo-react-common';
import Profile from './ProfileMenu';
import './layout.css';

const Layout = ({ children }: LayoutRouteProps): React.ReactElement => {
  return (
    <div className={`${process.env.REACT_APP_THEME_CLASS_STYLE} layout-wrapper`}>
      <AppBar className="bar-layout" positionMode="fixed">
        <AppBarSection className="topbar__logo">
          <img
            src={`${process.env.REACT_APP_PUBLIC_STORAGE}${process.env.REACT_APP_THEME_LOGO}`}
            alt="MotoGP"
            title="MotoGP"
            className='bar-logo'            
          />
        </AppBarSection>
        <AppBarSpacer />
        <AppBarSection>
          <Typography.h1 margin={0} className="title">
            {process.env.REACT_APP_TITLE}
          </Typography.h1>
        </AppBarSection>
        <AppBarSpacer />
        <AppBarSection>
          <Profile />
        </AppBarSection>
      </AppBar>
      <div className="content-layout">
        <div className="content">
          <div className="pane-content">
            {children}
          </div>
          <div className="k-appbar k-appbar-light k-appbar-bottom pane-content-bottom">
            <p>Contact: <a href={process.env.REACT_APP_THEME_EMAIL || 'events@dorna.com'}>{process.env.REACT_APP_THEME_EMAIL || 'events@dorna.com'}</a></p>
            <p style={{marginLeft:'calc(100% - 460px)'}}>For more news, videos, results and live timing visit <a href="http://www.motogp.com/" title="MotoGP" target="_blank" rel="noreferrer">www.motogp.com</a></p>
          </div>          
        </div>
      </div>
    </div>
  );
};

export default Layout;
