import { LoadingActionTypes, showLoading, hideLoading } from '../../actions/api';
import { ApiState } from '../@types/api';

const initialValues: ApiState = {
  loading: false,
};

const reducer = (state = initialValues, action: LoadingActionTypes): ApiState => {
  switch (action.type) {
    case showLoading.type:
      return {
        ...state,
        loading: true,
      };
    case hideLoading.type:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
