import { Button } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from '../../actions/api';
import { showNotification } from '../../actions/notifications';
import { StorageItem, StorageKind } from '../../constants/document';
import NotificationsTypes from '../../constants/notification';
import { Role } from '../../services/@types/account';
import documentsService from '../../services/documentsService';
import { FormInput } from '../common/forms/form-components';
import { requiredValidator, requiredArrayValidator } from '../common/forms/validators';
import TreeViewFolders from '../tree-view/TreeViewFoldersForm';
import styles from './AuthorizationsManager.module.css';

const AuthorizationsManager = ({ role, onSubmit }: { role: Role, onSubmit: (data:any)=> void }) => {
  const dispatch = useDispatch();
  const [blobs, setBlobs] = useState<StorageItem[]>([]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getItemsTreeByType = (items: any[], kind: StorageKind) => {
    const itemsFiltered = [];
    for (let i = 0; i < items.length; i++) {
      if (items[i] && items[i].kind === kind) {
        itemsFiltered.push(items[i]);
      }
      if (items[i].items && (items[i].items?.length ?? 0) > 0) {
        items[i].items = getItemsTreeByType(items[i].items, kind);
      }
    }

    return itemsFiltered;
  };

  const getData = async () => {
    try {
      dispatch(showLoading());
      const response = await documentsService.getDocumentsList();
      if (response) {
        setBlobs(response);
      }
    } catch (error: any) {
      setBlobs([]);
      dispatch(
        showNotification({
          message: 'Retrieve documents failed, try again later or contact the support team',
          type: NotificationsTypes.Error,
        }),
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  const onFormSubmit = (
    values: {
      [name: string]: any;
    },
    event?: React.SyntheticEvent<any, Event> | undefined,
  ): void => {
    if (event) {
      event.preventDefault();
    }
    onSubmit(values);
  };

  const requiredFieldsValidator = (formRenderProps: any): boolean => {
    var name = formRenderProps.valueGetter('name');

    return name;
  };

  return (
    <div className={styles["auth-manager-container"]}>
      <Form                
        key={JSON.stringify(role)}
        initialValues={role}
        onSubmit={onFormSubmit}        
        render={(formRenderProps: FormRenderProps): any => (
          <FormElement style={{width:"100%"}}>
            <fieldset className="k-form-fieldset" style={{maxHeight: "70%"}}>
              <div className={styles['fields-wrapper']}>
                <div className={styles['field-panel']}>
                  <Field
                    key="name"
                    name="name"
                    component={FormInput}
                    label="Name"
                    validator={requiredValidator}
                    id="name"                    
                  />
                </div>
                <div className={styles['field-panel-tree']}>
                  <Field
                    key="authorizations"
                    name="authorizations"
                    component={TreeViewFolders}
                    label="Authorizations"
                    validator={requiredArrayValidator}
                    id="authorizations"
                    items={getItemsTreeByType(blobs, StorageKind.folder)}
                    onChange={(value:any)=> formRenderProps.onChange("authorizations", { value })}
                  />
                </div>
              </div>
            </fieldset>
            <div role="toolbar" className="k-form-buttons">
              <Button
                themeColor="primary"
                disabled={!requiredFieldsValidator(formRenderProps)}
                onClick={formRenderProps.onSubmit}
              >
                Submit
              </Button>
            </div>
          </FormElement>
        )}
      />
    </div>
  );
};

export default AuthorizationsManager;
