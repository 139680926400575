import { DocumentKind, StorageItem, StorageKind } from '../../constants/document';
import { getDocumentKindByContentType } from '../../utils';

const TreeViewItem = ({
  item
}: any) => {
  const iconClassName = (item: StorageItem): string => {
    switch (getDocumentKindByContentType(item.contentType)) {
      case DocumentKind.image:
        return 'k-icon k-i-image';
      case DocumentKind.pdf:
        return 'k-icon k-i-file-pdf';
      case DocumentKind.office:
        return 'k-icon k-i-file-doc';
      default:
        return 'k-icon k-i-file';
    }
  };

  if (item.kind === StorageKind.document) {
    return (
      <>
        <span className={iconClassName(item)} key="0" />
        {item.text}        
      </>
    );
  } else {
    return (
      <span style={{ fontWeight: 'bold' }}>{item.text}</span>
    );
  }
};

export default TreeViewItem;
