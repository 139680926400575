import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showNotification } from '../../actions/notifications';
import { Role } from '../../services/@types/account';
import styles from './RoleManagement.module.css';
import { hideLoading, showLoading } from '../../actions/api';
import UsersService from "../../services/usersService";
import NotificationsTypes from '../../constants/notification';
import RolesList from './RolesList';
import AuthorizationsManager from '../authorizations-manager/AuthorizationsManager';

const newRole: Role = {
  id: "",
  name: "",
  code: "",
  authorizations: []
}

const RoleManagement = (): React.ReactElement => {
  const [roles, setRoles] = useState<Role[]>([]);
  const dispatch = useDispatch();
  const [selectedRole, setSelectedRole] = useState<Role | undefined>(undefined)

  useEffect(() => {
    let didCancel = false;

    if (!didCancel) {
      getRoles();
    }

    return (): void => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRoles = async () => {
    try {
      dispatch(showLoading());
      const roles = await UsersService.getAllRoles();
      if (roles.length>0)
      {
        const rolesResult : any[] = [...roles];
        rolesResult[0].selected = true;
        setRoles(rolesResult);
        setSelectedRole(roles[0]);  
      }    
    } catch (error: any) {
      dispatch(showNotification({ message: 'Unable to retrieve the list of roles.', type: NotificationsTypes.Error }));
    } finally {
      dispatch(hideLoading());
    }
  };

  const onSelected = async (id: string): Promise<void> => {
    const roleSelected = (roles ?? []).find(u=> u.id === id);

    setSelectedRole(roleSelected ?? newRole);
  };

  const addRole = (): void => {
    setSelectedRole(newRole);
  };

  const onSubmitRole = async (data:any) => {
    try {
      dispatch(showLoading());
      if (data.id === "")
      {
        var created = await UsersService.createRole({...data, code: data.name.replaceAll(' ','_').toLowerCase()});
        if (created)
        {
          setRoles([...roles, data]);
          dispatch(showNotification({ message: `Role ${data?.name} successfully updated.`, type: NotificationsTypes.Success }));
        }
      } else {
        var updated = await UsersService.updateRole(data);
        if (updated)
        {
          const role = roles?.find(r=> r.id === data.id); 
          const updatedRoles: any = roles?.filter(r=> r.id !== data.id);
          setRoles([...updatedRoles, data]);
          dispatch(showNotification({ message: `Role ${role?.name} successfully updated.`, type: NotificationsTypes.Success }));
        }    
      }      
    } catch (error: any) {
      dispatch(showNotification({ message: 'Unable to update the role.', type: NotificationsTypes.Error }));
    } finally {
      dispatch(hideLoading());
    }
  }

  return (
    <div className={styles['roles-wrapper']}>
      <RolesList roles={roles ?? []} onSelect={onSelected} onAddRole={addRole} />
      {
        selectedRole ? 
          <AuthorizationsManager 
            role={selectedRole ?? newRole}
            onSubmit={onSubmitRole}
          />
          : <></>
      }
      
    </div>
  );
};

export default RoleManagement;
