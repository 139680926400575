import { useEffect, useState } from 'react';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { useDispatch } from 'react-redux';
import { FormDropDownList } from '../forms/form-components';
import { Role } from '../../../services/@types/account';
import { hideLoading, showLoading } from '../../../actions/api';
import { showNotification } from '../../../actions/notifications';
import NotificationsTypes from '../../../constants/notification';
import UsersService from "../../../services/usersService";

const RoleSelector = (props: any): React.ReactElement => {
  const [roles, setRoles] = useState<Role[] | undefined>(undefined);
  const [selectedValue, setSelectedValue] = useState<Role | undefined>(undefined);
  const dispatch = useDispatch();

  useEffect(() => {
    if (roles === undefined || roles.length === 0) {
      getRoles();
    } 
    // setSelectedValue(roles?.find(r=> r.code === props.value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles]);

  const getRoles = async () => {
    try {
      dispatch(showLoading());
      const roles = await UsersService.getAllRoles();
      setRoles(roles);      
    } catch (error: any) {
      dispatch(showNotification({ message: 'Unable to retrieve the list of roles.', type: NotificationsTypes.Error }));
    } finally {
      dispatch(hideLoading());
    }
  };

  const onChange = (event: DropDownListChangeEvent) => {
    setSelectedValue(event.target.value);
  };

  return (
    <FormDropDownList
      data={roles}
      onChange={onChange}
      value={selectedValue}
      dataItemKey="code"
      textField="name"      
      {...props}      
    />
  );
};

export default RoleSelector;
