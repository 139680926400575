import { removeUser, setUser } from '../../actions/user';
import { UserActionTypes } from '../../actions/user';
import { User } from '../@types/user';

const initialValues: User = {
  id: '',
  businessId: 0,
  role: '',
  name: '',
  surname: '',
  email:'',
  authorizations:[]
};

const reducer = (state = initialValues, action: UserActionTypes): User => {
  switch (action.type) {
    case setUser.type:
      const userPayload = setUser.match(action) ? action.payload : initialValues;
      return {
        ...userPayload,
      };
    case removeUser.type:
      return {
        ...initialValues,
      };
    default:
      return state;
  }
};

export default reducer;
