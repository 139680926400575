const endpoints = {
  account: {
    signIn: 'v1/appId/{appId}/authentication/basic',
    authorizations: 'v1/appId/{appId}/authorizations'
  },
  users: {
    getAll: 'v1/appId/{appId}/users',
    getUser: 'v1/appId/{appId}/users/{userId}',
    getRoles: 'v1/appId/{appId}/roles',
    updateRole: 'v1/appId/{appId}/roles/update',
    createRole: 'v1/appId/{appId}/roles/create',
    createUser: 'v1/appId/{appId}/users/create',
    updateUser: 'v1/appId/{appId}/users/update',
    updatePassword: 'v1/appId/{appId}/users/password',
    activateUser: 'v1/appId/{appId}/users/{userId}/activate',
    deactivateUser: 'v1/appId/{appId}/users/{userId}/deactivate',
  },
  documents:{
    getDocument: "v1/app/{appId}/documents/get",
    createSharedLink: "v1/app/{appId}/documents/shared/create",
    openSharedFileLink: "v1/app/{appId}/documents/shared/file/{code}",
    openSharedFolderLink: "v1/app/{appId}/documents/shared/folder/{code}",
    full: "v1/app/{appId}/documents/full",
    upload: "v1/app/{appId}/documents/upload",
    delete: "v1/app/{appId}/documents/delete",
    rename: "v1/app/{appId}/documents/rename",
    folderRename: "v1/app/{appId}/documents/folder/rename",
    folderAdd: "v1/app/{appId}/documents/folder/create",
    folderDelete: "v1/app/{appId}/documents/folder/delete",
    folderContentDelete: "v1/app/{appId}/documents/folder/contentDelete",
  }
};

export default endpoints;
