import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SignIn } from '@dorna-corporate/corporate-react-components';
import { useNavigate } from 'react-router-dom';
import { getRedirectionUrl, getVersion } from '../../utils/';
import SignInService from '../../services/authenticationService';
import { setAccount, removeAccount } from '../../actions/account';
import './styles.css';
import { Roles } from '../../constants/app';
import { hideLoading, showLoading } from '../../actions/api';
import { showNotification } from '../../actions/notifications';
import NotificationsTypes from '../../constants/notification';
import usersService from '../../services/usersService';
import { Account } from '../../reducers/@types/account';
import { setUser } from '../../actions/user';
import { emailOrAccountValidator } from '../common/forms/validators';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = async ({ username, password }: any): Promise<void> => {
    try {
      dispatch(showLoading());
      const response = await SignInService.signin({ username, password });
      if (response) {
        const account: Account = {
          businessId: response.businessId,
          accessToken: response.accessToken,
          expires: response.expires,
          refreshToken: response.refreshToken,
          email: response.name,
          id: response.id
        }

        localStorage.setItem('account', JSON.stringify(response));
        dispatch(setAccount(account));

        var user:any ={};
        if (response.id)
        {
          user = await usersService.getUser({id: response.id});        
        } else {
          user = {
            id: "",
            name: account.email,
            surname: "",
            email: account.email,
            role: "Dorna",
            isActive: true
          }
        }

        const authorizations = await usersService.authorizations();

        localStorage.setItem('user', JSON.stringify({...user, businessId: account.businessId, authorizations}));
        dispatch(setUser({...user, businessId: account.businessId, authorizations}));
        
        const route = getRedirectionUrl(response.role as Roles);
        navigate(route);
      }
    } catch (error: any) {
      dispatch(removeAccount());
      dispatch(
        showNotification({ message: 'Sign in failed, review user name and password', type: NotificationsTypes.Error }),
      );      
    } finally {
      dispatch(hideLoading());
    }
  };

  const logout = (): void => {
    dispatch(removeAccount());
    localStorage.removeItem('account');    
  };

  return (
    <div className={`${process.env.REACT_APP_THEME_CLASS_STYLE}`} style={{width: '100%'}}>
      <SignIn
        onSignIn={login}
        version={getVersion()}
        siteName={process.env.REACT_APP_TITLE || ''}
        subTitle="Sign In"
        message={`Please contact ${process.env.REACT_APP_THEME_EMAIL || 'events@dorna.com'} for support`}
        userValidator={(value:string) => emailOrAccountValidator(value) ? "Please enter a valid account": ""}
        logo={`${process.env.REACT_APP_PUBLIC_STORAGE}${process.env.REACT_APP_THEME_LOGO}`}
      />
    </div>    
  );
};

export default Login;
