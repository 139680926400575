import { combineReducers } from '@reduxjs/toolkit';
import account from './account';
import user from './user';
import api from './api';
import notifications from './notifications';

const rootReducer = combineReducers({
  account,
  user,
  api,
  notifications
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
