import { Button, SplitButton, SplitButtonItem, Toolbar, ToolbarItem } from '@progress/kendo-react-buttons';
import { useSelector } from 'react-redux';
import { StorageKind } from '../../constants/document';
import { getAuthorizations } from '../../reducers/user/selectors';
import styles from './DocumentsActions.module.css';

const DocumentsActions = ({
  item,
  onClickAddFolder,
  onClickAddDocument,
  onClickRenameDocument,
  onClickRenameFolder,
  onClickDeleteDocument,
  onClickDeleteFolder,
  onClickShareDocument,
  onClickDeleteContentFolder
}: any) => {
  const authorizations = useSelector(getAuthorizations);

  return item === null ||
    !authorizations.filter((a) => (item.path === undefined ? false : item.path.startsWith(a.resource)))[0]?.edit ? (
      <Toolbar></Toolbar>
  ) : (
    <Toolbar>
      {item.kind === StorageKind.document ? (
        <>
          <ToolbarItem>
            <Button
              icon="edit-tools"
              className={styles['action-toolbar-button']}
              title="Rename file"
              onClick={() => onClickRenameDocument(item)}
            >
              Rename file
            </Button>
          </ToolbarItem>
          <ToolbarItem>
            <Button
              icon="delete"
              className={styles['action-toolbar-button']}
              title="Delete file"
              onClick={() => onClickDeleteDocument(item)}
            >
              Delete file
            </Button>
          </ToolbarItem>
          <ToolbarItem>
            <Button
              icon="share"
              className={styles['action-toolbar-button']}
              title="Share file"
              onClick={() => onClickShareDocument(item)}
            >
              Share file
            </Button>
          </ToolbarItem>
        </>
      ) : (
        <>
          <ToolbarItem>
            <SplitButton text="Insert" icon="plus" onItemClick={(event)=> event.item.text === "Folder" ? onClickAddFolder(item) : onClickAddDocument(item) }>
              <SplitButtonItem  icon="folder-add" text="Folder" />
              <SplitButtonItem icon="file-add" text="File" />
            </SplitButton>            
          </ToolbarItem>
          <ToolbarItem>
            <Button
              icon="edit-tools"
              className={styles['action-toolbar-button']}
              title="Rename folder"
              onClick={() => onClickRenameFolder(item)}
            >
              Rename Folder
            </Button>
          </ToolbarItem>
          <ToolbarItem>
            <SplitButton text="Delete" icon="delete" onItemClick={(event)=> event.item.text === "Content and folder" ? onClickDeleteFolder(item) : onClickDeleteContentFolder(item) }>
              <SplitButtonItem  icon="files" text="Only content" />
              <SplitButtonItem icon="folder" text="Content and folder" />
            </SplitButton>            
          </ToolbarItem>          
          <ToolbarItem>
            <Button
              icon="share"
              className={styles['action-toolbar-button']}
              title="Share folder"
              onClick={() => onClickShareDocument(item)}
            >
              Share Folder
            </Button>
          </ToolbarItem>
        </>
      )}
    </Toolbar>
  );
};

export default DocumentsActions;
