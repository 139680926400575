import { useState } from 'react';
import TextSearchFilter from '../filters/textSearch/TextSearchFilter';
import ClearButton from '../filters/ClearButton';
import AddButton from '../filters/AddButton';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import styles from './UsersList.module.css';

interface TextSearchCompositeFilterDescriptor extends CompositeFilterDescriptor {
  field: string;
}

interface UsersFilterProps {
  onFilter(filter: CompositeFilterDescriptor[]): void;
  onAddClick(): void;
  users: any[];
}

const UsersFilter = ({ onFilter, onAddClick, users }: UsersFilterProps): React.ReactElement => {
  const [filterState, setFilterState] = useState<TextSearchCompositeFilterDescriptor[]>([]);
  const [clearState, setClearState] = useState(false);

  const onChangeTextSearchFilter = (value: any) => {
    if (value.length > 0) {
      const filterFields = ['name', 'surname', 'email'];
      const compositeFilter: TextSearchCompositeFilterDescriptor = {
        logic: 'or',
        field: 'textSearch',
        filters: filterFields.map((field: string) => {
          const newFilter: FilterDescriptor = {
            field: field,
            operator: 'contains',
            value: value,
          };
          return newFilter;
        }),
      };

      const filtered = filterState.filter(
        (filter: TextSearchCompositeFilterDescriptor) => filter.field !== 'textSearch',
      );
      const newCompositeFilters: TextSearchCompositeFilterDescriptor[] = [compositeFilter];
      const newFilters = [...filtered, ...newCompositeFilters];

      setFilterState(newFilters);
      onFilter(newFilters);
    } else {
      const filtered = filterState.filter(
        (filter: TextSearchCompositeFilterDescriptor) => filter.field !== 'textSearch',
      );
      setFilterState(filtered);
      onFilter(filtered);
    }
  };

  const onClearClick = () => {
    setFilterState([]);
    onFilter([]);
    setClearState(!clearState);
  };

  return (
    <div className={styles["filter-wrapper"]}>
      <TextSearchFilter onFilter={onChangeTextSearchFilter} reset={clearState} />
      <ClearButton onClick={onClearClick} />
      <AddButton onClick={onAddClick} />
    </div>
  );
};

export default UsersFilter;
