import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Account } from '../../reducers/@types/account';

export const setAccount = createAction<Account>('@Account/SET_ACCOUNT');
export const removeAccount = createAction('@Account/REMOVE_ACCOUNT');

export type AccountActionTypes = typeof setAccount | typeof removeAccount;

export const sessionExpired = createAsyncThunk('@Account/SESSION_EXPIRED', async (params, { dispatch }) => {
  dispatch(removeAccount());  
});

export const logout = createAsyncThunk('@Account/LOGOUT', async (params, { dispatch }) => {
  dispatch(removeAccount());
  localStorage.removeItem('account');  
});
