import { DropDownList } from '@progress/kendo-react-dropdowns';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Hint, Label, Error } from '@progress/kendo-react-labels';
import {
  TreeView,
  TreeViewExpandChangeEvent,
  processTreeViewItems,
  handleTreeViewCheckChange,
} from '@progress/kendo-react-treeview';
import { useEffect, useState } from 'react';
import { StorageItem, StorageKind } from '../../constants/document';
import { Authorization } from '../../reducers/@types/user';

const TreeViewFoldersForm = (props: any) => {
  const { validationMessage, touched, label, id, valid, disabled, hint, type, optional, items, value, ...others } =
    props;
  const rootFolderName = process.env.REACT_APP_THEME_ROOT_FOLDER ?? 'MotoGP INFORMATION';
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const [treeItems, setTreeItems] = useState<any[]>([]);
  const [expand, setExpand] = useState<any>({ ids: [rootFolderName], idField: 'text' });
  const [check, setCheck] = useState<any>({ ids: [], idField: 'path' });
  const readWriteItems = [{id: 0, name: "Read", edit: false}, {id: 1, name: "Read & Write", edit: true}];
  const [authorizations, setAuthorizations] = useState<Authorization[]>(value);

  useEffect(() => {
    if (items && items.length > 0) {
      setExpand({ ids: getItemNamesByKind(items[0], StorageKind.folder), idField: 'text' });

      if (value.length > 0) {
        const ids: any = value.filter((x: any) => x.authorizationType === 'path').map((x: any) => x.resource);
        setCheck((c: any) => ({ ...c, ids }));                        
      }      
    }
  }, [items, value]);

  useEffect(()=> {
    props.onChange(authorizations);
  }, [authorizations])

  useEffect(() => {
    if (items && items.length > 0) {
      setTreeItems(items.map((x: any) => markDisableIfParentPathChecked(x)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, check]);

  const markDisableIfParentPathChecked = (item: StorageItem): any => {
    const markedItem = {
      ...item,
      items: item.items?.map((x) => markDisableIfParentPathChecked(x)),
      disable: check.ids.some((x: any) => item.path.startsWith(x) && item.path !== x),
    };

    return markedItem;
  };

  const getItemNamesByKind = (blob: StorageItem, kind: StorageKind): string[] => {
    let paths: string[] = [];
    if (blob.kind === kind) {
      paths.push(blob.text);
      if (blob.items != null) {
        for (let i = 0; i < blob.items.length; i++) {
          paths.push(blob.items[i].text);
        }
      }
    }
    return paths;
  };

  const onExpandChange = (event: TreeViewExpandChangeEvent) => {
    let ids = expand.ids.slice();
    const index = ids.indexOf(event.item.text);

    index === -1 ? ids.push(event.item.text) : ids.splice(index, 1);
    setExpand({ ids, idField: 'text' });
  };

  const onItemClick = (event: any) => {   
    let ids = expand.ids.slice();
    const index = ids.indexOf(event.item.text);

    index === -1 ? ids.push(event.item.text) : ids.splice(index, 1);
    setExpand({ ids, idField: 'text' });   
  };

  const onCheckChange = (event: any) => {
    const checkedValues = handleTreeViewCheckChange(event, check, items);

    if ((checkedValues as any).ids.some((x:any)=> x === event.item.path))
    {
      // add or update
      setAuthorizations([...authorizations.filter(x=> x.resource !== event.item.path), {
        authorizationType: 'path',
        resource: event.item.path,
        edit: false,
      }]);
    } else {
      // delete
      setAuthorizations(authorizations.filter(x=> x.resource !== event.item.path));      
    }
    setCheck(checkedValues);    
  };

  const handleDropDownAuth = (event:any, item:any) => {
    setAuthorizations([...authorizations.filter(x=> x.resource !== item.path), {
      authorizationType: 'path',
      resource: item.path,
      edit: event.value.edit,
    }]);
  }

  const renderItem = ({ item }: any) => {
    if (item.checked) {
      return (
        <div>
          <span style={{ marginRight: '10px' }} onClick={(event: any) => onItemClick({ ...event, item })}>
            {item.text}
          </span>
          <DropDownList 
            data={readWriteItems} 
            onChange={(event:any)=> handleDropDownAuth(event, item)} 
            style={{ maxWidth: '110px' }} 
            defaultValue={readWriteItems[0]}
            value={authorizations?.find(x=> x.resource === item.path)?.edit ? readWriteItems[1] : readWriteItems[0]}
            dataItemKey={"id"}             
            textField={"name"} 
          />
        </div>
      );
    } else {
      return <span onClick={onItemClick}>{item.text}</span>;
    }
  };

  return (
    <FieldWrapper>
      <Label editorId={id} editorValid={valid} editorDisabled={disabled} optional={optional}>
        {label}
      </Label>
      <div className={'k-form-field-wrap'}>
        <TreeView
          data={processTreeViewItems(treeItems, {
            expand: expand,
            check: check,
          })}
          expandIcons={true}
          onExpandChange={onExpandChange}
          aria-multiselectable={false}
          checkboxes={true}
          onCheckChange={onCheckChange}
          disableField={'disable'}
          item={renderItem}
          {...others}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
      </div>
    </FieldWrapper>
  );
};

export default TreeViewFoldersForm;
