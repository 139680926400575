import request from './request';
import {
  SignInRequest,
  SignInResponse  
} from './@types/account';
import endpoints from './endpoints';

const signin = async ({ username, password }: SignInRequest): Promise<SignInResponse> =>
  request({
    baseURL: process.env.REACT_APP_API_BASE_URL_SECURITY,
    url: endpoints.account.signIn.replace("{appId}", process.env.REACT_APP_ID ?? ''),
    method: 'POST',
    data: {
      email: username,
      password
    },
  });

const authenticationService = {
  signin
};

export default authenticationService;
