import { Button } from '@progress/kendo-react-buttons';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from '../../actions/api';
import { showNotification } from '../../actions/notifications';
import { DocumentKind, StorageItem, StorageItemFull } from '../../constants/document';
import NotificationsTypes from '../../constants/notification';
import documentsService from '../../services/documentsService';
import { getDocumentKindByContentType } from '../../utils';

const DocumentsViewer = (item: StorageItemFull) => {
  const dispatch = useDispatch();
  const [storageFullItem, setStorageFullItem] = useState<StorageItemFull | undefined>(undefined);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.path]);

  const getData = async () => {
    try {
      dispatch(showLoading());
      if (item.url) {
        setStorageFullItem(item);
      } else {
        const response = await documentsService.getDocumentInfo(item.path);
        if (response) {
          setStorageFullItem(response);
        }
      }
    } catch (error: any) {
      setStorageFullItem(undefined);
      dispatch(
        showNotification({
          message: 'Retrieve document failed, try again later or contact the support team',
          type: NotificationsTypes.Error,
        }),
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  const getDefaultRender = () => {
    if (storageFullItem)
      switch (getDocumentKindByContentType(storageFullItem.contentType)) {
        case DocumentKind.image:
          return getPreviewImage();
        case DocumentKind.pdf:
          return getPreviewDocument();
        default:
          return getPreviewNotAvailable();
      }
  };

  const forceDownload = (url: string, fileName: string) => {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL(this.response);
      var tag = document.createElement('a');
      tag.href = imageUrl;
      tag.download = fileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.send();
  }

  const getPreviewDocument = () => {
    return (
      <>
        <div
          style={{ display: 'flex', width: '100%', justifyContent: 'center', textAlign: 'center', marginTop: '5px' }}
        >
          <h4 style={{ marginBottom: '5px' }}>{storageFullItem?.text}</h4>
          <Button
            icon="download"
            className="btn-action-add"
            title="Download file"
            onClick={() => forceDownload(storageFullItem?.url ?? '', storageFullItem?.text ?? '')}
            style={{ marginTop: '5px', marginLeft: '10px' }}
          />
        </div>
        <iframe 
          src={storageFullItem?.url}
          title={storageFullItem?.text} 
          style={{ height: '100%', width: '100%', marginTop: '10px', alignSelf: 'center' }} />        
      </>
    );
  };

  const getPreviewImage = () => {
    return (
      <>
        <div
          style={{ display: 'flex', width: '100%', justifyContent: 'center', textAlign: 'center', marginTop: '5px' }}
        >
          <h4 style={{ marginBottom: '5px' }}>{storageFullItem?.text}</h4>
          <Button
            icon="download"
            className="btn-action-add"
            title="Download file"
            onClick={() => forceDownload(storageFullItem?.url ?? '', storageFullItem?.text ?? '')}
            style={{ marginTop: '5px', marginLeft: '10px' }}
          />
        </div>
        <img
          src={storageFullItem?.url}
          alt={storageFullItem?.url}
          style={{ maxHeight: '100%', maxWidth: '100%', marginTop: '10px', alignSelf: 'center' }}
        />
      </>
    );
  };

  const getPreviewNotAvailable = () => {
    return (
      <>
        <div
          style={{ display: 'flex', width: '100%', justifyContent: 'center', textAlign: 'center', marginTop: '5px' }}
        >
          <h4 style={{ marginBottom: '5px' }}>{storageFullItem?.text}</h4>
          <Button
            icon="download"
            className="btn-action-add"
            title="Download file"
            onClick={() => onDownloadDocument(storageFullItem)}
            style={{ marginTop: '5px', marginLeft: '10px' }}
          />
        </div>
        <h3 style={{ alignSelf: 'center' }}>Preview Not Available</h3>
      </>
    );
  };

  const getLoadingDocument = () => {
    return <h3 style={{ alignSelf: 'center' }}>Loading document</h3>;
  };

  const onDownloadDocument = async (item: StorageItem | undefined) => {
    try {
      dispatch(showLoading());
      window.open(item?.url);
    } catch (error: any) {
      dispatch(showNotification({ message: `Document not available.`, type: NotificationsTypes.Error }));
    } finally {
      dispatch(hideLoading());
    }
  };

  return (
    <div style={{ display: 'flex', height: '100%', width: '100%', justifyContent: 'center', textAlign: 'center' }}>
      {storageFullItem === null ? (
        getLoadingDocument()
      ) : (
        <div style={{ height: '100%', width: '100%', justifyContent: 'center' }}>{getDefaultRender()}</div>
      )}
    </div>
  );
};

export default DocumentsViewer;
