import { useState } from 'react';
import { Avatar } from '@progress/kendo-react-layout';
import { ListBox } from '@progress/kendo-react-listbox';
import { process, SortDescriptor } from '@progress/kendo-data-query';
import { Chip } from '@progress/kendo-react-buttons';
import styles from './UsersList.module.css';
import UsersFilter from './UsersFilters';
import { useEffect } from 'react';
import { UserResume } from '../../services/@types/account';
import { UserListProps } from './@types';
import _ from 'lodash';

interface DataState {
  sort: SortDescriptor[];
  filter: any;
}

const initialFilter: DataState = {
  sort: [{ field: 'name', dir: 'asc' }],
  filter: {
    logic: 'or',
    filters: [],
  },
};

const UsersList = ({ users, onSelect, onAddUser }: UserListProps): React.ReactElement => {
  const [filterState, setFilterState] = useState(initialFilter);
  const [usersFiltered, setUsersFiltered] = useState(users);
  
  useEffect(() => {
    if (filterState.filter.filters.length > 0) {
      setUsersFiltered(process(users, filterState).data);
    } else if (users.length > 0) {
      setUsersFiltered([...users]);
    }
  }, [users, filterState]);

  const getInitials = (name: string, surname: string): string =>
    name && surname ? `${name.trim().charAt(0)} ${surname.trim().charAt(0)}` : '';

  const onFilterChange = (filters: any[]): void => {
    if (filters.length > 0) {
      const newFilter = {
        logic: 'and',
        filters: filters,
      };
      setFilterState({ ...filterState, filter: newFilter });
    } else if (!_.isEqual(filterState.filter.filters, filters)) {
      setFilterState(initialFilter);
    }
  };

  const updateUserList = (id: string): UserResume[] => {
    const users: UserResume[] = usersFiltered.map((item: UserResume) => {
      return { ...item, selected: item.id === id };
    });

    return users;
  };

  const onClick = (event: any) => {
    if (event) {
      event.preventDefault();
    }

    const userId = event.currentTarget.dataset.id;

    setUsersFiltered(updateUserList(userId));
    onSelect(userId);
  };

  const ListItem = ({ dataItem }: any): React.ReactElement => {
    const { id, name, surname, email, selected, role } = dataItem as UserResume;

    return (
      <div
        role="none"
        key={id}
        className={selected ? `${styles['selected']} ${styles['list-item']}` : `${styles['list-item']}`}
        onClick={onClick}
        data-id={id}
      >
        <div className={styles['user-avatar']}>
          <Avatar rounded="full" type="text">
            {getInitials(name, surname)}
          </Avatar>
        </div>
        <div className="text-ellipsis">
          <p className={`${styles['list-item-title']} text-ellipsis`}>{`${name} ${surname}`}</p>
          <span className={styles['list-item-text']}>{email}</span>
        </div>
        <div className={styles['user-role']}>
          <Chip rounded="full" text={role} />
        </div>
      </div>
    );
  };

  return (
    <div className={`${styles['users-list']} ${styles['filter-element']}`}>
      <UsersFilter users={usersFiltered} onFilter={onFilterChange} onAddClick={onAddUser} />
      <ListBox
        data={usersFiltered}
        item={ListItem}
        style={{ flex: 1, display: 'flex' }}
        className={styles['listbox-content']} 
        textField="email"
        selectedField="selected"
      />
      <div className={styles['users-footer']}>{`${usersFiltered.length} contacts`}</div>
    </div>
  );
};

export default UsersList;
