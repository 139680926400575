import { dornaMail, motogpMail } from "../../../constants/accounts";

export const requiredValidator = (value: string): string => (value ? '' : 'This field is required.');

export const passwordValidator = (value: string): string => (value 
                                                                ? value.length > 3 && new RegExp(/^[\w]+$/).test(value)   ? '' : 'Password must be at least 4 characters long and contain only letters and numbers.'
                                                                : 'This field is required.');

export const requiredArrayValidator = (value: any[]): string => (value && value.length > 0 ? '' : 'This field is required.');

export const emailValidator = (value: string): string => (new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w]+$/).test(value) ? '' : 'Please enter a valid email.');
export const accountValidator = (value: string): string => (value.length > 3 && new RegExp(/^[\w]+$/).test(value) ? '' : 'Username must be at least 4 characters long and contain only letters and numbers.');
export const emailOrAccountValidator = (value: string): string =>  value === null || value === undefined
                                                                        ? 'This field is required.' 
                                                                        : value.includes("@") ? emailValidator(value) : accountValidator(value);

export const isDornaUser = (value: string) :boolean => value.endsWith(motogpMail) || value.endsWith(dornaMail);


