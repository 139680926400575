import { useState } from 'react';
import { Form, FormElement, Field, FormRenderProps } from '@progress/kendo-react-form';
import { IMessageConfirmation, UserForm } from './@types';
import Confirmation from '../dialog/confirmation/Confirmation';
import styles from './UserDetail.module.css';
import { requiredValidator } from '../common/forms/validators';
import { FormInput, FormSwitch } from '../common/forms/form-components';
import RoleSelector from '../common/base/RoleSelector';
import { Button } from '@progress/kendo-react-buttons';

interface IUserDetailProps {
  user?: UserForm;
  onSubmit(user: UserForm): void;
  setUserStatus(user: UserForm): void;
}

const initialModalState: IMessageConfirmation = {
  message: '',
  callback: () => undefined,
};

const UserDetail = ({ user, onSubmit, setUserStatus }: IUserDetailProps): React.ReactElement => {
  const { name = '', surname = '', isActive = false } = user || {};
  const [showConfirmationMessage, setShowConfirmationMessage] = useState<boolean>(false);
  const [confirmationModal, setConfirmationModal] = useState<IMessageConfirmation>(initialModalState);

  const onClickActivate = ({ target }: any): void => {
    if (user) {
      const { value } = target;
      setConfirmationModal({
        message: `Are you sure you want to ${isActive ? 'deactivate' : 'activate'} ${name}?`,
        callback: () => setUserStatus({ ...user, isActive: value }),
      });
      setShowConfirmationMessage(true);
    }
  };

  const onValidated = (): void => {
    setShowConfirmationMessage(false);
    setConfirmationModal(initialModalState);
    confirmationModal.callback();
  };

  const onDecline = (): void => {
    setShowConfirmationMessage(false);
    setConfirmationModal(initialModalState);
  };

  const onFormSubmit = (
    values: {
      [name: string]: any;
    },
    event?: React.SyntheticEvent<any, Event> | undefined,
  ): void => {
    if (event) {
      event.preventDefault();
    }

    onSubmit({ ...(values as UserForm) });
  };

  const requiredFieldsValidator = (formRenderProps: any): boolean => {
    var name = formRenderProps.valueGetter('name');
    var surname = formRenderProps.valueGetter('surname');
    var email = formRenderProps.valueGetter('email');
    var role = formRenderProps.valueGetter('role');

    return name && surname && email && role && role.code;
  };

  return (
    <div className={styles['user-detail']}>
      <h3
        className={['k-form-legend', styles['custom-legend']].join(' ')}
      >{`${name} ${surname} contact information:`}</h3>
      <Form
        key={JSON.stringify(user)}
        initialValues={user}
        onSubmit={onFormSubmit}
        render={(formRenderProps: FormRenderProps): any => (
          <FormElement>
            <fieldset className="k-form-fieldset">
              <div className={styles['fields-wrapper']}>
                <div className={styles['field-panel']}>
                <Field
                    key="email"
                    name="email"
                    component={FormInput}
                    label="Email / Username"
                    id="email"
                    disabled={true}
                  />
                  <Field
                    key="name"
                    name="name"
                    component={FormInput}
                    label="Name"
                    validator={requiredValidator}
                    id="name"
                  />
                  <Field
                    key="surname"
                    name="surname"
                    component={FormInput}
                    label="Surname"
                    validator={requiredValidator}
                    id="surname"
                  />
                  <Field
                    key="role"
                    name="role"
                    component={RoleSelector}
                    label="Role"
                    validator={requiredValidator}
                    id="role"
                  />
                  <Field
                    name="isActive"
                    component={FormSwitch}
                    label="Active"
                    id="active-switch"
                    disabled={!(user !== undefined)}
                    onChange={onClickActivate}
                    checked={user?.isActive}
                  />
                </div>
              </div>
            </fieldset>
            <div role="toolbar" className="k-form-buttons">
              <Button
                themeColor="primary"
                disabled={!requiredFieldsValidator(formRenderProps)}
                onClick={formRenderProps.onSubmit}
              >
                Submit
              </Button>
            </div>
          </FormElement>
        )}
      />
      {showConfirmationMessage && (
        <Confirmation
          data-testid="confirmation-dialog"
          onAccept={onValidated}
          onDecline={onDecline}
          message={confirmationModal.message}
        />
      )}
    </div>
  );
};

export default UserDetail;
