import { useSelector, useDispatch } from 'react-redux';
import { NotificationGroup } from '@progress/kendo-react-notification';
import { Slide } from '@progress/kendo-react-animation';
import { Notification } from './@types/notification';
import './styles.css';
import notificationsSelector from '../../reducers/notifications/selectors';
import { hideNotification } from '../../actions/notifications';
import Toast from './NotificationItem';

const Notifications = (): React.ReactElement => {
  const notifications = useSelector(notificationsSelector);
  const dispatch = useDispatch();

  const onClose = (id: string): void => {
    dispatch(hideNotification(id));
  };

  return (
    <>
      <NotificationGroup className="notification-group-wrapper">
        <Slide direction="down" transitionEnterDuration={500} transitionExitDuration={500}>
          {notifications
          && notifications.messages
          && notifications.messages.map((notification: Notification) => (
            <Toast
              onClose={onClose}
              notification={notification}
              key={notification.id}
              data-testid="toast"
            />
          ))}
        </Slide>
      </NotificationGroup>
    </>
  );
};

export default Notifications;
