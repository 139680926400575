import { nanoid } from '@reduxjs/toolkit';
import { NotificationsState } from '../@types/notifications';
import { Notification } from '../../components/notifications/@types/notification';
import { showNotification, NotificationActionTypes, hideNotification } from '../../actions/notifications';
import NotificationsTypes from '../../constants/notification';

const initialValues: NotificationsState = {
  messages: [],
};

const reducer = (state = initialValues, action: NotificationActionTypes): NotificationsState => {
  switch (action.type) {
    case showNotification.type:
      const showPayload = showNotification.match(action)? action.payload: { message: '', type: NotificationsTypes.None};
      return {
        ...state,
        messages: [...state.messages, {
          id: nanoid(),
          message: showPayload.message,
          type: showPayload.type,
        }],
      };
    case hideNotification.type:
      const hidePayload = hideNotification.match(action)? action.payload: '';
      return {
        ...state,
        messages: state.messages.filter((notification: Notification) => notification.id !== hidePayload),
      };
    default:
      return state;
  }
};

export default reducer;
