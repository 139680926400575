import { Roles } from "../constants/app";
import { DocumentKind } from "../constants/document";
import routes from '../constants/routes.json';

export const getVersion = (): string => `${process.env.REACT_APP_VERSION}`;

export const getRedirectionUrl = (role: Roles) => {
  switch (role) {
    case Roles.Admin:
      return routes['documents'];
    default:
      return routes['documents'];
  }
};

export const getDocumentKindByExtension= (fileName:string): DocumentKind => {
  const is = (fileName:string, ext:string) => new RegExp(`.${ext}`).test(fileName);

  if (is(fileName, "pdf")) {
    return DocumentKind.pdf;
  } else if (is(fileName, "jpg|png|jpeg")) {
    return DocumentKind.image;
  } else if (is(fileName, "doc|docx|xls|xlsx|odt")) {
    return DocumentKind.office;
  } else {
    return DocumentKind.unknown;
  }
}

export const getDocumentKindByContentType = (contentType:string|undefined): DocumentKind => {
  switch(contentType) {
    case "image/png":
    case "image/jpeg":
    case "image/jpg":
    case "image/gif":
    case "image/bmp":
    case "image/svg+xml":
        return DocumentKind.image;
    case "application/pdf":
      return DocumentKind.pdf;
    case "application/msword":
    case "application/vnd.ms-powerpoint":
    case "application/vnd.ms-excel":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return DocumentKind.office;
    default:
      return DocumentKind.unknown;      
  }
}
