import { Dialog } from '@progress/kendo-react-dialogs';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';

const RenameItem = ({ onSubmit, onCancel, title, currentName }: any) => {
  return (
    <Dialog title={title} onClose={onCancel}>
      <Form
        onSubmit={onSubmit}
        initialValues={{name:currentName}}
        render={(formRenderProps) => (
          <FormElement
            style={{
              maxWidth: 650,
              width: 300,
            }}
          >
            <fieldset className={'k-form-fieldset'}>
              <div className="mb-3">
                <Field name={'name'} component={Input} label={'New name'} />
              </div>
            </fieldset>
            <div className="k-form-buttons">
              <button
                type={'submit'}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                disabled={!formRenderProps.allowSubmit}
              >
                Submit
              </button>
            </div>
          </FormElement>
        )}
      />
    </Dialog>
  );
};

export default RenameItem;
