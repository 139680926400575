import { TreeView, TreeViewExpandChangeEvent, processTreeViewItems } from '@progress/kendo-react-treeview';
import { useEffect, useState } from 'react';
import { StorageItem, StorageKind } from '../../constants/document';
import AddFolder from './AddFolder';
import UploadFile from './UploadFile';
import TreeViewItem from './TreeViewItem';
import RenameItem from './RenameItem';
import SharedLinkCreator from '../shared-links/SharedLink';
import DocumentsActions from '../documents-manager/DocumentsActions';
import "./TreeViewDocuments.css";

const TreeViewDocuments = ({ items, selectFile, selectedFile, addNewFolder, addNewDocument, renameDocument, 
                            renameFolder, deleteDocument, deleteFolder, deleteContentFolder }: any) => {
  const rootFolderName = process.env.REACT_APP_THEME_ROOT_FOLDER ?? 'MotoGP INFORMATION';
  const [expand, setExpand] = useState<any>({ ids: [rootFolderName], idField: 'text' });
  const [newFolderVisible, setNewFolderVisible] = useState<boolean>(false);
  const [newFileVisible, setNewFileVisible] = useState<boolean>(false);
  const [renameItemVisible, setRenameItemVisible] = useState<any>({visible: false, title: "", item: null, callBack: null});
  const [sharedLinkVisible, setSharedLinkVisible] = useState<any>({visible: false, item: null, callBack: null});
  const [selectedPath, setSelectedPath] = useState<string>(''); 
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>();  

  const onExpandChange = (event: TreeViewExpandChangeEvent) => {
    let ids = expand.ids.slice();
    const index = ids.indexOf(event.item.text);

    index === -1 ? ids.push(event.item.text) : ids.splice(index, 1);
    setExpand({ ids, idField: 'text' });
    if (event.item.kind === StorageKind.folder) {
      setSelectedPath(event.item.path);
    }
  };

  const onItemClick = (event: any) => {
    if (
      event.syntheticEvent.target.className !== 'k-button-icon k-icon k-i-folder-add' &&
      event.syntheticEvent.target.className !== 'k-button-icon k-icon k-i-file-add'
    ) {
      let ids = expand.ids.slice();
      const index = ids.indexOf(event.item.text);

      index === -1 ? ids.push(event.item.text) : ids.splice(index, 1);
      setExpand({ ids, idField: 'text' });
      selectFile(event.item);
      setSelectedItemIds([event.itemHierarchicalIndex]);
      if (event.item.kind === StorageKind.folder) {
        setSelectedPath(event.item.path);
      }
    }
  };

  const onClickAddFolder = (item: StorageItem) => {
    setSelectedPath(item.path);
    setNewFolderVisible(true);
  };

  const onSubmitAddFolder = (result: any) => {
    addNewFolder(selectedPath, result.folderName);
    setNewFolderVisible(false);
  };

  const onClickAddDocument = (item: StorageItem) => {
    setSelectedPath(item.path);
    setNewFileVisible(true);
  };

  const onClickRenameDocument = (item:StorageItem) => {
    setRenameItemVisible({visible: true, title: "Rename file", item, callBack: onSubmitRenameDocument});    
  }

  const onClickRenameFolder = (item:StorageItem) => {
    setRenameItemVisible({visible: true, title: "Rename folder", item, callBack: onSubmitRenameFolder});    
  }

  const onClickShareDocument = async (item:StorageItem) => {    
    setSharedLinkVisible({visible: true, item, callBack: ()=>setSharedLinkVisible({visible: false})});    
  }

  const onSubmitAddDocument = (items: StorageItem[]) => {
    addNewDocument(selectedPath, items);    
  };

  const onSubmitRenameDocument = (item:StorageItem, newFileName:string) => {    
    renameDocument(item, newFileName);
    setRenameItemVisible({visible:false, title:"", item: null});
  }

  const onSubmitRenameFolder = (item:StorageItem, newFolderName:string) => {    
    renameFolder(item, newFolderName);
    setRenameItemVisible({visible:false, title:"", item: null});
  }

  const onClickDeleteDocument = (item:StorageItem) => {
    deleteDocument(item);
  }

  const onClickDeleteFolder = (item:StorageItem) => {
    deleteFolder(item);
  }
  
  const onClickDeleteContentFolder = (item:StorageItem) => {
    deleteContentFolder(item);
  }

  return (
    <>
      <DocumentsActions 
        item={selectedFile}
        onClickAddFolder={onClickAddFolder} 
        onClickAddDocument={onClickAddDocument} 
        onClickRenameDocument={onClickRenameDocument}
        onClickRenameFolder={onClickRenameFolder}
        onClickDeleteDocument={onClickDeleteDocument} 
        onClickDeleteFolder={onClickDeleteFolder}
        onClickShareDocument={onClickShareDocument}  
        onClickDeleteContentFolder={onClickDeleteContentFolder}
      />
      <TreeView
        data={
          processTreeViewItems(items, {
          expand: expand,
          select: selectedItemIds
        })}
        expandIcons={true}
        onExpandChange={onExpandChange}
        onItemClick={onItemClick}
        aria-multiselectable={false}
        checkboxes={false}
        item={(props: any) => (
          <TreeViewItem 
            {...props} 
            onClickAddFolder={onClickAddFolder} 
            onClickAddDocument={onClickAddDocument} 
            onClickRenameDocument={onClickRenameDocument}
            onClickRenameFolder={onClickRenameFolder}
            onClickDeleteDocument={onClickDeleteDocument} 
            onClickDeleteFolder={onClickDeleteFolder}
            onClickShareDocument={onClickShareDocument}
          />
        )}
      />
      {newFolderVisible === true ? (
        <AddFolder onSubmit={(result: any) => onSubmitAddFolder(result)} onCancel={() => setNewFolderVisible(false)} />
      ) : (
        <></>
      )}
      {renameItemVisible.visible === true ? (
        <RenameItem 
          onSubmit={(result: any) => renameItemVisible.callBack(renameItemVisible.item, result.name)} 
          onCancel={() => setRenameItemVisible({visible:false, title:"", item: null})} 
          title={renameItemVisible.title}
          currentName={renameItemVisible.item.text}
        />
      ) : (
        <></>
      )}
      {newFileVisible === true ? (
        <UploadFile
          onSubmit={(result: any) => onSubmitAddDocument(result)}
          onCancel={() => setNewFileVisible(false)}
          onFinishUpload={() => {setNewFileVisible(false)}}
          folderName={selectedPath}
        />
      ) : (
        <></>
      )}
      {sharedLinkVisible.visible && (
        <SharedLinkCreator
          data-testid="confirmation-dialog"
          onClose={sharedLinkVisible.callBack}
          item={sharedLinkVisible.item}
        />
      )}
    </>
  );
};

export default TreeViewDocuments;
