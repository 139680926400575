import { setAccount, removeAccount, AccountActionTypes } from '../../actions/account';
import { Account } from '../@types/account';

const initialValues: Account = {
  businessId: 0,
  accessToken: '',
  refreshToken: '',
  expires: 0,  
  email:'',
  id: ''
};

const reducer = (state = initialValues, action: AccountActionTypes): Account => {
  switch (action.type) {
    case setAccount.type:
      const accountPayload = setAccount.match(action) ? action.payload : initialValues;
      return {
        ...accountPayload,
      };
    case removeAccount.type:
      return {
        ...initialValues,
      };
    default:
      return state;
  }
};

export default reducer;
