import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from '../../actions/api';
import { showNotification } from '../../actions/notifications';
import { StorageKind, StorageItemFull } from '../../constants/document';
import NotificationsTypes from '../../constants/notification';
import documentsService from '../../services/documentsService';
import DocumentsViewer from '../documents-viewer/DocumentsViewer';
import TreeViewDocuments from '../tree-view/TreeViewDocuments';

const SharedLinkFolderViewer = () => {
  const dispatch = useDispatch();
  const [blobs, setBlobs] = useState<StorageItemFull[]>([]);
  const [fileSelected, setFileSelected] = useState<any>({});
  
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const getData = async () => {
    try {
      dispatch(showLoading());
      var linkCode = window.location.pathname.split('/').pop() ?? '';
      const response = await documentsService.openSharedFolderLink(linkCode);
      if (response) {
        setBlobs(response);
      }
    } catch (error: any) {
      setBlobs([]);
      dispatch(
        showNotification({
          message: 'Requested document is not available, contact with the department which gave the link.',
          type: NotificationsTypes.Error,
        }),
      );
      setTimeout(() => {        
        window.location.href = `/?${new Date().getTime()}`;
      }, 2000);
    } finally {
      dispatch(hideLoading());
    }
  };

  const getDocumentsViewer = () => {
    if (fileSelected != null && fileSelected.kind === StorageKind.document)
    {
      return(<DocumentsViewer  {...fileSelected}/>)
    } else {
      return (
        <p style={{fontSize:"14px", marginTop:"10px", textAlign:'center'}}>
          Navigate through the tree and click to preview the file.
        </p>
      )
    }
  }  

  return (
    <div style={{display:"flex", height:"100%"}}>
        <div style={{flex:"auto", overflow:"auto", width:"40%"}}>
          <TreeViewDocuments
            items={blobs}
            selectFile={setFileSelected}
            addNewFolder={null}
            addNewDocument={null}
            renameDocument={null}
            renameFolder={null}
            deleteDocument={null}
            deleteFolder={null}            
          />
        </div>
        <div style={{flex:"auto", width: "60%"}}>
          {getDocumentsViewer()}
        </div>        
      </div>      
  );
};

export default SharedLinkFolderViewer;

