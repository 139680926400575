import { createAction } from "@reduxjs/toolkit";
import NotificationsTypes from "../../constants/notification";

interface NotificationAction {
  message: string;
  type: NotificationsTypes;
}

export const showNotification = createAction<NotificationAction>('@Notifications/SHOW_NOTIFICATION');
export const hideNotification = createAction<string>('@Notifications/REMOVE_NOTIFICATION');

export type NotificationActionTypes = typeof showNotification | typeof hideNotification;
