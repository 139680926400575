import { Button } from '@progress/kendo-react-buttons';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from '../../actions/api';
import { showNotification } from '../../actions/notifications';
import { DocumentKind, StorageItem, StorageItemFull } from '../../constants/document';
import NotificationsTypes from '../../constants/notification';
import documentsService from '../../services/documentsService';
import { getDocumentKindByContentType } from '../../utils';

const SharedLinkFileViewer = () => {
  const dispatch = useDispatch();
  const [storageFullItem, setStorageFullItem] = useState<StorageItemFull | undefined | null>(undefined);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    try {
      dispatch(showLoading());
      var linkCode = window.location.pathname.split('/').pop() ?? '';
      const response = await documentsService.openSharedFileLink(linkCode);
      if (response) {
        setStorageFullItem(response);
      }
    } catch (error: any) {
      setStorageFullItem(null);
      dispatch(
        showNotification({
          message: 'Requested document is not available, contact with the department which gave the link.',
          type: NotificationsTypes.Error,
        }),
      );
      setTimeout(() => {        
        window.location.href = `/?${new Date().getTime()}`;
      }, 2000);
    } finally {
      dispatch(hideLoading());
    }
  };

  const getDefaultRender = () => {
    if (storageFullItem)
      switch (getDocumentKindByContentType(storageFullItem.contentType)) {
        case DocumentKind.image:
          return (
            <img
              src={storageFullItem.url}
              alt={storageFullItem.url}
              style={{ maxHeight: '100%', maxWidth: '100%', marginTop: '10px', alignSelf: 'center' }}
            />
          );
        case DocumentKind.pdf:
          return (
            <iframe src={storageFullItem.url} title={storageFullItem.text} style={{ height: '100%', width: '100%' }} />
          );
        default:
          return getPreviewNotAvailable();
      }
  };

  const getPreviewNotAvailable = () => {
    return (
      <>
        <div
          style={{ display: 'flex', width: '100%', justifyContent: 'center', textAlign: 'center', marginTop: '5px' }}
        >
          <h4 style={{ marginBottom: '5px' }}>{storageFullItem?.text}</h4>
          <Button
            icon="download"
            className="btn-action-add"
            title="Download file"
            onClick={() => onDownloadDocument(storageFullItem)}
            style={{ marginTop: '5px' }}
          />
        </div>
        <h3 style={{ alignSelf: 'center' }}>Preview Not Available</h3>
      </>
    );
  };

  const getLoadingDocument = () => {
    return <h3 style={{ alignSelf: 'center' }}>Loading document</h3>;
  };

  const onDownloadDocument = async (item: StorageItem | undefined | null) => {
    try {
      dispatch(showLoading());
      // window.open(item?.url);
      var link = document.createElement('a');
      link.href = item?.url ?? '';
      link.download = item?.text ?? '';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error: any) {
      dispatch(showNotification({ message: `Document not available.`, type: NotificationsTypes.Error }));
    } finally {
      dispatch(hideLoading());
    }
  };

  return (
    <div style={{ display: 'flex', height: '100%', width: '100%', justifyContent: 'center', textAlign: 'center' }}>
      {!storageFullItem ? (
        getLoadingDocument()
      ) : (
        <div style={{ height: '100%', width: '100%', justifyContent: 'center' }}>{getDefaultRender()}</div>
      )}
    </div>
  );
};

export default SharedLinkFileViewer;
