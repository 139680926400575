import { memo, useCallback, useEffect, useState } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import debounce from 'lodash/debounce';

interface TextSearchFilterProps {
  onFilter(text: string): void;
  reset: boolean;
  label?: string;
}

const TextSearchFilter = ({ onFilter, reset, label }: TextSearchFilterProps): React.ReactElement => {
  const [selectedValue, setSelectedValue] = useState<string>();
  
  useEffect(() => {
    if (selectedValue) {
      setSelectedValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  const debouncedKeyPress = useCallback(
    debounce(() => onFilter(selectedValue || ''), 500, { trailing: true }),
    [selectedValue]
  );

  const onChange = ({ value }: any) => {
    setSelectedValue(value);
  };

  useEffect(() => {
    if (selectedValue !== undefined) {
      debouncedKeyPress();
    }
    // Cancel the debounce on useEffect cleanup.
    return debouncedKeyPress.cancel;
  }, [selectedValue, debouncedKeyPress]);

  return (
    <div className="filter">
      {label && <div>{label + ':'}</div>}
      <Input id="textSearch" name="textSearch" onChange={onChange} value={selectedValue} style={{ width: '100%' }} />
    </div>
  );
};

export default memo(TextSearchFilter);
