import { useState } from 'react';
import { Form, FormElement, Field, FormRenderProps } from '@progress/kendo-react-form';
import { IMessageConfirmation, UserForm } from './@types';
import Confirmation from '../dialog/confirmation/Confirmation';
import styles from './UserDetail.module.css';
import { passwordValidator } from '../common/forms/validators';
import { FormInput } from '../common/forms/form-components';
import { Button } from '@progress/kendo-react-buttons';

interface IUserChangePasswordProps {
  user?: UserForm;
  onSubmit(user: UserForm): void;
}

const initialModalState: IMessageConfirmation = {
  message: '',
  callback: () => undefined,
};

const UserChangePassword = ({ user, onSubmit }: IUserChangePasswordProps): React.ReactElement => {
  const { email, name } = user || {};
  const [showConfirmationMessage, setShowConfirmationMessage] = useState<boolean>(false);
  const [confirmationModal, setConfirmationModal] = useState<IMessageConfirmation>(initialModalState);

  const onValidated = (): void => {
    setShowConfirmationMessage(false);
    setConfirmationModal(initialModalState);
    confirmationModal.callback();
  };

  const onDecline = (): void => {
    setShowConfirmationMessage(false);
    setConfirmationModal(initialModalState);
  };

  const onFormSubmit = (values: any, event?: React.SyntheticEvent<any, Event> | undefined): void => {
    if (event) {
      event.preventDefault();
    }

    setConfirmationModal({
      message: `Are you sure you want to change password for user ${name}?`,
      callback: () => onSubmit({ ...values }),
    });
    setShowConfirmationMessage(true);
  };

  const requiredFieldsValidator = (formRenderProps: any): boolean => {
    var password = formRenderProps.valueGetter('password');
    var passwordConfirmation = formRenderProps.valueGetter('passwordConfirmation');

    return password && passwordConfirmation && password === passwordConfirmation;
  };

  return (
    <div className={styles['user-change-password']}>
      <h3 className={['k-form-legend', styles['custom-legend']].join(' ')}>{'Change password:'}</h3>
      <Form
        key={JSON.stringify({ password: '', passwordConfirmation: '', email: email })}
        initialValues={{ password: '', passwordConfirmation: '', email: email }}
        onSubmit={onFormSubmit}
        render={(formRenderProps: FormRenderProps): any => (
          <FormElement>
            <fieldset className="k-form-fieldset">
              <div className={styles['fields-wrapper']}>
                <div className={styles['field-panel']}>
                  <Field
                    key="password"
                    name="password"
                    component={FormInput}
                    type="password"
                    label="Password"
                    validator={passwordValidator}
                    id="password"
                  />
                  <Field
                    key="passwordConfirmation"
                    name="passwordConfirmation"
                    component={FormInput}
                    type="password"
                    label="Confirm password"
                    validator={passwordValidator}
                    id="passwordConfirmation"
                  />
                </div>
              </div>
            </fieldset>
            <div role="toolbar" className="k-form-buttons">
              <Button
                themeColor="primary"
                disabled={!requiredFieldsValidator(formRenderProps)}
                onClick={formRenderProps.onSubmit}
              >
                Update password
              </Button>
            </div>
          </FormElement>
        )}
      />

      {showConfirmationMessage && (
        <Confirmation
          data-testid="confirmation-dialog"
          onAccept={onValidated}
          onDecline={onDecline}
          message={confirmationModal.message}
        />
      )}
    </div>
  );
};

export default UserChangePassword;
