import { Route, Routes } from 'react-router-dom';
import routes from '../../constants/routes.json';
import DocumentsManager from '../documents-manager/DocumentsManager';
import Login from '../login/Login';
import UsersManagement from '../users/UsersManagement';
import RoleManagement from '../roles/RoleManagement';
import PrivateRoute from './PrivateRoute';
import SharedLinkFileViewer from '../shared-links/SharedLinkFileViewer';
import SharedLinkFolderViewer from '../shared-links/SharedLinkFolderViewer';
import Layout from '../layout/Layout';

const MainRoutes = (): React.ReactElement => (
  <Routes>
    <Route path={routes.users} element={<PrivateRoute><UsersManagement /></PrivateRoute>} />
    <Route path={routes.roles} element={<PrivateRoute><RoleManagement /></PrivateRoute>} />
    <Route path={routes.documents} element={<PrivateRoute><DocumentsManager /></PrivateRoute>} />
    <Route path={routes.sharedFile} element={<Layout><SharedLinkFileViewer /></Layout>} />
    <Route path={routes.sharedFolder} element={<Layout><SharedLinkFolderViewer /></Layout>} />
    <Route path={routes.signin} element={<Login />} />
    <Route path='*' element={<Login />} />
  </Routes>
);

export default MainRoutes;
