import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from '../../actions/api';
import { showNotification } from '../../actions/notifications';
import { StorageKind, StorageItem } from '../../constants/document';
import NotificationsTypes from '../../constants/notification';
import documentsService from '../../services/documentsService';
import Confirmation from '../dialog/confirmation/Confirmation';
import DocumentsViewer from '../documents-viewer/DocumentsViewer';
import TreeViewDocuments from '../tree-view/TreeViewDocuments';
import { IMessageConfirmation } from '../users/@types';

const initialModalState: IMessageConfirmation = {
  message: '',
  callback: () => undefined,
};

const DocumentsManager = () => {
  const dispatch = useDispatch();
  const [blobs, setBlobs] = useState<StorageItem[]>([]);
  const [fileSelected, setFileSelected] = useState<any>({});
  const [showConfirmationMessage, setShowConfirmationMessage] = useState<boolean>(false);
  const [confirmationModal, setConfirmationModal] = useState<IMessageConfirmation>(initialModalState);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const getData = async () => {
    try {
      dispatch(showLoading());
      const response = await documentsService.getDocumentsList();
      if (response) {
        setBlobs(response)
      }
    } catch (error: any) {
      setBlobs([])
      dispatch(showNotification({ message: 'Retrieve documents failed, try again later or contact the support team', type: NotificationsTypes.Error }));      
    } finally {
      dispatch(hideLoading());
    }
  };

  const getDocumentsViewer = () => {
    if (fileSelected != null && fileSelected.kind === StorageKind.document)
    {
      return(<DocumentsViewer  {...fileSelected}/>)
    } else {
      return (
        <p style={{fontSize:"14px", marginTop:"10px", textAlign:'center'}}>
          Navigate through the tree and click to preview the file.
        </p>
      )
    }
  }

  const insertItemIntoTree = (path:string, blob: StorageItem, item: StorageItem) =>{
    if(blob.path === path){
      if (item.kind === StorageKind.document) 
        blob.items?.push({...item, path: item.path + item.text});        
      else
        blob.items?.push(item);

      blob.items = blob.items?.sort((a,b)=> {
        if(a.text < b.text) { return -1; }
        if(a.text > b.text) { return 1; }
        return 0;
      });
    }else if (blob.items != null){
      for(let i=0; i < blob.items.length; i++){
        insertItemIntoTree(path, blob.items[i], item);
      }          
    }    
  }

  const deleteItemIntoTree = (blob: StorageItem, item: StorageItem) =>{
    if (blob.items != null){
      var filtereddItems = blob.items.filter(i=> i.path == item.path);
      if (filtereddItems.length > 0)
      {
        blob.items = blob.items.filter(i=> i.path != item.path);
      }
      else {
        for(let i=0; i < blob.items.length; i++){
          deleteItemIntoTree(blob.items[i], item);
        }
      }
    }    
  }

  const addFolder = async (path:string, folderName:string) => {
    if (await addStorageFolder(path, folderName))
    {
      var item: StorageItem = {
        path: path + folderName + "/",
        text: folderName,
        kind: StorageKind.folder,
        url: undefined,
        contentType: undefined,
        items: []
      };

      var copyBlobs = [...blobs];
      insertItemIntoTree(path, copyBlobs[0], item);
      setBlobs(copyBlobs);
    }
  }

  const addDocument = (path:string, items:StorageItem[]) => {
    var copyBlobs = [...blobs];
    
    for (const item of items) {
      insertItemIntoTree(path, copyBlobs[0], item);
    }

    setBlobs(copyBlobs);
  }

  const deleteDocument = async (item:StorageItem) => {
    if (await deleteStorageDocument(item))
    {
      var copyBlobs = [...blobs];
      deleteItemIntoTree(copyBlobs[0], item);
      setBlobs(copyBlobs);
    }
  }

  const deleteFolder = async (item:StorageItem) => {
    if (await deleteStorageFolder(item))
    {
      var copyBlobs = [...blobs];
      deleteItemIntoTree(copyBlobs[0], item);
      setBlobs(copyBlobs);
    }
  }

  const deleteContentFolder = async (item:StorageItem) => {
    if (await deleteStorageContentFolder(item))
    {
      var copyBlobs = [...blobs];
      item.items?.forEach(element => {
        deleteItemIntoTree(copyBlobs[0], element);  
      });      
      setBlobs(copyBlobs);
    }
  }

  const deleteStorageDocument = async (item:StorageItem): Promise<Boolean> => {
    var succeed = false;
    try {
      dispatch(showLoading());
      await documentsService.deleteDocument(item.text, item.path.substring(0,item.path.length - item.text.length));
      setFileSelected(null);
      succeed = true;
    } catch (error: any) {
      dispatch(showNotification({ message: 'Delete document failed, try again later or contact the support team', type: NotificationsTypes.Error }));      
    } finally {
      dispatch(hideLoading());      
    }
    return succeed;
  }

  const renameDocument = async (item:StorageItem, newFileName: string) => {
    try {
      dispatch(showLoading());
      await documentsService.renameDocument(item.text, newFileName, item.path.substring(0,item.path.length - item.text.length));
      await getData();
      setFileSelected(null);
    } catch (error: any) {
      dispatch(showNotification({ message: 'Rename document failed, try again later or contact the support team', type: NotificationsTypes.Error }));      
    } finally {      
      dispatch(hideLoading());      
    }
  }

  const renameFolder = async (item:StorageItem, newFolderName: string) => {
    try {
      dispatch(showLoading());
      await documentsService.renameFolder(item.text, newFolderName, item.path.endsWith("/") 
                                                                      ? item.path.substring(0,item.path.length - item.text.length -1) 
                                                                      : item.path.substring(0,item.path.length - item.text.length));
      await getData();
      setFileSelected(null);
    } catch (error: any) {
      dispatch(showNotification({ message: 'Rename folder failed, try again later or contact the support team', type: NotificationsTypes.Error }));      
    } finally {
      dispatch(hideLoading());      
    }
  }

  const deleteStorageFolder = async (item:StorageItem): Promise<Boolean> => {
    var succeed = false;
    try {
      dispatch(showLoading());
      await documentsService.deleteFolder(item.path);
      setFileSelected(null);
      succeed = true;
    } catch (error: any) {
      dispatch(showNotification({ message: 'Delete folder failed, try again later or contact the support team', type: NotificationsTypes.Error }));      
    } finally {
      dispatch(hideLoading());      
    }
    return succeed;
  }

  const deleteStorageContentFolder = async (item:StorageItem): Promise<Boolean> => {
    var succeed = false;
    try {
      dispatch(showLoading());
      await documentsService.deleteContentFolder(item.path);
      setFileSelected(null);
      succeed = true;
    } catch (error: any) {
      dispatch(showNotification({ message: 'Delete content failed, try again later or contact the support team', type: NotificationsTypes.Error }));      
    } finally {
      dispatch(hideLoading());      
    }
    return succeed;  
  }

  const addStorageFolder = async (path:string, folderName:string): Promise<Boolean> => {
    var succeed = false;
    try {
      dispatch(showLoading());
      await documentsService.addFolder(folderName, path);
      setFileSelected(null);
      succeed = true;
    } catch (error: any) {
      dispatch(showNotification({ message: 'Create folder failed, try again later or contact the support team', type: NotificationsTypes.Error }));      
    } finally {
      dispatch(hideLoading());
    }
    return succeed;    
  }

  const onRenameDocument = (item:StorageItem, newFileName: string) => {
    setConfirmationModal({
      message: `Are you sure you want to rename the document?`,
      callback: () => renameDocument(item, newFileName)
    });
    setShowConfirmationMessage(true);    
  }

  const onDeleteDocument = (item:StorageItem) => {
    setConfirmationModal({
      message: `Are you sure you want to delete the document?`,
      callback: () => deleteDocument(item)
    });
    setShowConfirmationMessage(true);    
  }

  const onDeleteFolder = (item:StorageItem) => {
    setConfirmationModal({
      message: `Are you sure you want to delete the folder?`,
      callback: () => deleteFolder(item)
    });
    setShowConfirmationMessage(true);    
  }

  const onDeleteContentFolder = (item:StorageItem) => {
    setConfirmationModal({
      message: `Are you sure you want to delete the content in the folder?`,
      callback: () => deleteContentFolder(item)
    });
    setShowConfirmationMessage(true);    
  }

  const onDecline = (): void => {
    setShowConfirmationMessage(false);
    setConfirmationModal(initialModalState);
  };

  const onValidated = (): void => {
    setShowConfirmationMessage(false);
    setConfirmationModal(initialModalState);
    confirmationModal.callback();
  };

  return (
    <div style={{display:"flex", height:"100%"}}>
        <div style={{flex:"auto", width:"40%"}}>
          <TreeViewDocuments
            items={blobs}
            selectFile={setFileSelected}
            selectedFile={fileSelected}
            addNewFolder={addFolder}
            addNewDocument={addDocument}
            renameDocument={onRenameDocument}
            renameFolder={renameFolder}
            deleteDocument={onDeleteDocument}
            deleteFolder={onDeleteFolder}           
            deleteContentFolder={onDeleteContentFolder} 
          />
        </div>
        <div style={{flex:"auto", width: "60%"}}>          
          {getDocumentsViewer()}
        </div>
        {showConfirmationMessage && (
          <Confirmation
            onAccept={onValidated}
            onDecline={onDecline}
            message={confirmationModal.message}
          />
        )}
      </div>
      
  );
};

export default DocumentsManager;

