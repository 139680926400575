import { Navigate, useLocation, LayoutRouteProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsSignedIn } from '../../reducers/account/selectors';
import Layout from '../layout/Layout';
import routes from '../../constants/routes.json';
import { setAccount } from '../../actions/account';
import { setUser } from '../../actions/user';

const validateUser = (dispatch: any): boolean => {
  const account = localStorage.getItem('account');
  const user = localStorage.getItem('user');
  if (account && user) {
    dispatch(setAccount({ ...JSON.parse(account)}));    
    dispatch(setUser({ ...JSON.parse(user)}));    
    return true;
  }
  return false;
};

const PrivateRoute = ({ children }: LayoutRouteProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  let signedIn = useSelector(selectIsSignedIn);

  if (!signedIn) {
    signedIn = validateUser(dispatch);
  }

  return signedIn === true ? (
    <Layout>{children}</Layout>
  ) : (
    <Navigate
      to={routes.signin}
      replace
      state={{
        path: location.pathname,
      }}
    />
  );
};

export default PrivateRoute;
