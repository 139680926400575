export enum StorageKind {
  folder=1,
  document=2
}

export interface StorageItem {
  url: string | undefined;
  text: string;
  kind: StorageKind;  
  contentType: string | undefined;
  items: any[] | undefined;
  path: string
}

export interface StorageItemFull extends StorageItem {
  url: string | undefined;
}

export interface SharedLink {
  codeLink: string;
  liveTo: Date
}

export enum DocumentKind {
  unknown,
  image,
  pdf,
  office
}
