import request from './request';
import {
  Role,
  UserResume,
  UserRequest,
  Authorization,
  PasswordRequest
} from './@types/account';
import endpoints from './endpoints';
import { User } from '../reducers/@types/user';

const getAllUsers = async (): Promise<UserResume[]> =>
  request({
    baseURL: process.env.REACT_APP_API_BASE_URL_SECURITY,
    url: endpoints.users.getAll.replace("{appId}", process.env.REACT_APP_ID ?? ''),
    method: 'GET'
  });

const getUser = async ({id}: UserRequest): Promise<UserResume> =>
  request({
    baseURL: process.env.REACT_APP_API_BASE_URL_SECURITY,
    url: endpoints.users.getUser.replace("{userId}", id).replace("{appId}", process.env.REACT_APP_ID ?? ''),
    method: 'GET'
  });

const getAllRoles = async (): Promise<Role[]> =>
  request({
    baseURL: process.env.REACT_APP_API_BASE_URL_SECURITY,
    url: endpoints.users.getRoles.replace("{appId}", process.env.REACT_APP_ID ?? ''),
    method: 'GET'
  });

const updateRole = async (role: Role): Promise<boolean> =>
  request({
    baseURL: process.env.REACT_APP_API_BASE_URL_SECURITY,
    url: endpoints.users.updateRole.replace("{appId}", process.env.REACT_APP_ID ?? ''),
    method: 'POST',
    data:{...role, appId: process.env.REACT_APP_ID}
  });

const createRole = async (role: Role): Promise<string> =>
  request({
    baseURL: process.env.REACT_APP_API_BASE_URL_SECURITY,
    url: endpoints.users.createRole.replace("{appId}", process.env.REACT_APP_ID ?? ''),
    method: 'POST',
    data:{...role, appId: process.env.REACT_APP_ID}
  });

const createUser = async (user: User): Promise<string> =>
  request({
    baseURL: process.env.REACT_APP_API_BASE_URL_SECURITY,
    url: endpoints.users.createUser.replace("{appId}", process.env.REACT_APP_ID ?? ''),
    method: 'POST',
    data: {...user, appId: process.env.REACT_APP_ID}
  });

const updateUser = async (user: User): Promise<string> =>
  request({
    baseURL: process.env.REACT_APP_API_BASE_URL_SECURITY,
    url: endpoints.users.updateUser.replace("{appId}", process.env.REACT_APP_ID ?? ''),
    method: 'POST',
    data:{...user, appId: process.env.REACT_APP_ID}
  });

const updatePassword = async (passwordRequest: PasswordRequest): Promise<boolean> =>
  request({
    baseURL: process.env.REACT_APP_API_BASE_URL_SECURITY,
    url: endpoints.users.updatePassword.replace("{appId}", process.env.REACT_APP_ID ?? ''),
    method: 'POST',
    data:{...passwordRequest, appId: process.env.REACT_APP_ID}
  });

const activate = async ({id}: UserRequest): Promise<boolean> =>
  request({
    baseURL: process.env.REACT_APP_API_BASE_URL_SECURITY,
    url: endpoints.users.activateUser.replace("{userId}", id).replace("{appId}", process.env.REACT_APP_ID ?? ''),
    method: 'POST'
  });

const deactivate = async ({id}: UserRequest): Promise<boolean> =>
  request({
    baseURL: process.env.REACT_APP_API_BASE_URL_SECURITY,
    url: endpoints.users.deactivateUser.replace("{userId}", id).replace("{appId}", process.env.REACT_APP_ID ?? ''),
    method: 'POST'
  });

const authorizations = async (): Promise<Authorization[]> =>
  request({
    baseURL: process.env.REACT_APP_API_BASE_URL_SECURITY,
    url: endpoints.account.authorizations.replace("{appId}", process.env.REACT_APP_ID ?? ''),
    method: 'GET'
  });

const usersService = {
  getAllUsers,
  getUser,
  getAllRoles,
  activate,
  deactivate,
  updateRole,
  createRole,
  authorizations,
  createUser,
  updateUser,
  updatePassword
};

export default usersService;
