import { Button } from '@progress/kendo-react-buttons';

interface ClearButtonProps {
  onClick(): void
}

const ClearButton = ({ onClick }: ClearButtonProps): React.ReactElement => {
  return (
    <div className="filter-button">
      <Button onClick={onClick} icon='filter-clear' title='Clear filters' />
    </div>
  );
};

export default ClearButton;
