import { useSelector } from 'react-redux';
import MainRoutes from './components/routing/Routes';
import './styles/main.scss';
import Loading from './components/loading/Loading';
import loadingSelector from './reducers/api/selectors';
import { BrowserRouter } from 'react-router-dom';
import Notifications from './components/notifications/Notifications';

function App() {
  require(`./styles/${process.env.REACT_APP_THEME_CLASS_STYLE}.scss`);
  const loading = useSelector(loadingSelector);
  return (
    <div className={`${process.env.REACT_APP_THEME_CLASS_STYLE}`} style={{width:"100%"}}>
      <BrowserRouter >
        { loading && <Loading />}
        <Notifications />
        <MainRoutes />
      </BrowserRouter>
    </div>
    
  );
}

export default App;
